<template>
  <div>
    <h2 class="mb-4">Quantitative Volumetric Analysis: Results</h2>

    <p class="mb-2">
      a) Fill in the data table below using the data you collected during the experiment and any
      associated calculations.
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="width: 25%"></td>
            <td style="width: 25%; text-align: center; font-weight: bold">Trial 1</td>
            <td style="width: 25%; text-align: center; font-weight: bold">Trial 2</td>
            <td style="width: 25%; text-align: center; font-weight: bold">Trial 3</td>
          </tr>

          <tr v-for="result in labResults" :key="result.value">
            <td>
              <stemble-latex :content="result.property" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs[result.value1]" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs[result.value2]" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs[result.value3]" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="Colour of solution after titration" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <v-select
                v-model="inputs.colour1"
                :items="options"
                label="Select colour:"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <v-select
                v-model="inputs.colour2"
                :items="options"
                label="Select colour:"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <v-select
                v-model="inputs.colour3"
                :items="options"
                label="Select colour:"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </p>

    <p class="mb-2 mt-4">b) Fill in the table below to summarize the results of the experiment.</p>

    <v-simple-table dense style="max-width: 450px">
      <thead>
        <tr>
          <td>Average Molarity of NaOH (M)</td>
          <td class="centered-input py-2 px-2 mx-0">
            <calculation-input v-model="inputs.concNaOHAvg" />
          </td>
        </tr>
        <tr>
          <td>Actual Molarity of NaOH (M)</td>
          <td class="centered-input py-2 px-2 mx-0">
            <calculation-input v-model="inputs.trueConcNaOH" />
          </td>
        </tr>
        <tr>
          <td>Percent Error (%)</td>
          <td class="centered-input py-2 px-2 mx-0">
            <calculation-input v-model="inputs.pctError" />
          </td>
        </tr>
      </thead>
    </v-simple-table>

    <p class="mb-2 mt-6">
      c) Please upload the data sheet from the experiment as a pdf file using the upload field
      below.
    </p>

    <p class="mb-0">
      <v-file-input v-model="attachments" multiple accept="application/pdf" />
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'McMaster1A03Lab1Results',
  components: {
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        concHCl: null,
        volHCl: null,
        molHCl: null,

        volInit1: null,
        volFinal1: null,
        volNaOH1: null,
        molNaOH1: null,
        concNaOH1: null,
        colour1: null,

        volInit2: null,
        volFinal2: null,
        volNaOH2: null,
        molNaOH2: null,
        concNaOH2: null,
        colour2: null,

        volInit3: null,
        volFinal3: null,
        volNaOH3: null,
        molNaOH3: null,
        concNaOH3: null,
        colour3: null,

        concNaOHAvg: null,
        trueConcNaOH: null,
        pctError: null,
      },
      options: [
        {text: 'Colourless', value: 'colourless'},
        {text: 'Faint pink', value: 'faintPink'},
        {text: 'Pink', value: 'pink'},
        {text: 'Dark Pink', value: 'darkPink'},
        {text: 'Blue', value: 'blue'},
      ],
      attachments: [],
      labResults: [
        {
          property: 'Initial burette volume (mL)',
          value1: 'volInit1',
          value2: 'volInit2',
          value3: 'volInit3',
        },
        {
          property: 'Final burette volume (mL)',
          value1: 'volFinal1',
          value2: 'volFinal2',
          value3: 'volFinal3',
        },
        {
          property: 'Volume of NaOH used (mL)',
          value1: 'volNaOH1',
          value2: 'volNaOH2',
          value3: 'volNaOH3',
        },
        {
          property: 'Molarity of HCl solution (M)',
          value1: 'concHCl',
          value2: 'concHCl',
          value3: 'concHCl',
        },
        {
          property: 'Volume of HCl solution used (mL)',
          value1: 'volHCl',
          value2: 'volHCl',
          value3: 'volHCl',
        },
        {
          property: 'Moles of HCl (mol)',
          value1: 'molHCl',
          value2: 'molHCl',
          value3: 'molHCl',
        },
        {
          property: 'Moles of NaOH (mol)',
          value1: 'molNaOH1',
          value2: 'molNaOH2',
          value3: 'molNaOH3',
        },
        {
          property: 'Molarity of NaOH (M)',
          value1: 'concNaOH1',
          value2: 'concNaOH2',
          value3: 'concNaOH3',
        },
      ],
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
